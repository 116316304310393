export class AppSettings {
   public static API_ENDPOINT    = 'https://api.3plfulfillmentprep.com/apiblogs/';
  //  public static API_ENDPOINT    = 'http://192.168.19.52:8000/apiblogs/';
   public static IMAGE_URL_TEMP  = 'https://api.3plfulfillmentprep.com/storage/uploads/blogs/temp/';
   // public static IMAGE_URL_TEMP  = 'http://192.168.19.52:8000/uploads/blogs/temp/';
   public static IMAGE_URL       = 'https://3pl-uploads.s3.amazonaws.com/';
   public static PROJECT_ID      = 1;

   public static blogStatus         = ['Pending', 'Active', 'Draft'];
   public static verifiedStatuses   = [{'name': 'Pending', 'value': 0}, {'name': 'Approved', 'value': 1}];

   public static cities  = [
    {"id": 11, "name" : "Islamabad"},
    {"id": 5,  "name" : "Rawalpindi"}
   ];

   public static sitesList  = [
    { name : "3plfulfillmentprep.com",           url: "www.3plfulfillmentprep.com",             stagingUrl : "3pl.collegeessay.org"},
   ];

   public static sitesName = [
     "MyPerfectWords", "College Essay"
   ];


   public static pageTypes  = [
    // { name : "Service Page", val: "service", route: '/services'  },
    { name : "Guide",    val: "blog",    route: '/blog'     },
    { name : "Blog",     val: "guide",   route: '/guide'     }
   ];

   public static serviceTypes  = [
    { name : "New Service Page",         val: "parent"    },
    { name : "Existing Service Page",    val: "child"     }
   ];

   public static blogTypes  = [
    { name : "New Guide",            val: "parent"    },
    { name : "Existing Guides",       val: "child"     }
   ];
   public static blogTypesRaw  = [
    { name : "New Blog",            val: "parent"    },
    { name : "Existing Blogs",       val: "child"     }
   ];

   public static pageStyles  = [
    { name : "Article Style Service Page",     val: "article-style", route: '/article'   },
    // { name : "Standard Service Page",          val: "standard-style", route: '/article'   }
   ];

   public static blogStyles  = [
    { name : "Template 1",     val: "template-1", route: '/blog' },
    { name : "Template 2",     val: "template-2", route: '/blog' },
   ];

   public static roles = ["Admin","Proof Reader", "Blogger"];

   public static expertise = [
     "Science","English","Maths"
   ];

   public static snippetTypes = [
    'PDF','Advertisment','Expert Tips', 'Colored Box'
  ];

  public static slotHoursArr24       =
                      [
                       {val: '00' ,name:'12 AM'},
                       {val: '01' ,name:'01 AM'},
                       {val: '02' ,name:'02 AM'},
                       {val: '03' ,name:'03 AM'},
                       {val: '04' ,name:'04 AM'},
                       {val: '05' ,name:'05 AM'},
                       {val: '06' ,name:'06 AM'},
                       {val: '07' ,name:'07 AM'},
                       {val: '08' ,name:'08 AM'},
                       {val: '09' ,name:'09 AM'},
                       {val: '10' ,name:'10 AM'},
                       {val: '11' ,name:'11 AM'},
                       {val: '12' ,name:'12 PM'},
                       {val: '13' ,name:'01 PM'},
                       {val: '14' ,name:'02 PM'},
                       {val: '15' ,name:'03 PM'},
                       {val: '16' ,name:'04 PM'},
                       {val: '17' ,name:'05 PM'},
                       {val: '18' ,name:'06 PM'},
                       {val: '19' ,name:'07 PM'},
                       {val: '20' ,name:'08 PM'},
                       {val: '21' ,name:'09 PM'},
                       {val: '22' ,name:'10 PM'},
                       {val: '23' ,name:'11 PM'},

                     ];
}
